import React from 'react'

import { Router } from "@reach/router"

import PageGoalPreview from 'components/pageDataPreview/PageGoalPreview'

const GoalPreview = () => (
  <Router basepath={`/en/goal_preview`}>
    <PageGoalPreview path={'/:slug'} lang={'en'} />
  </Router>
)

export default GoalPreview
